<template>
  <div style="padding-left: 1rem;">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div @click="addItem" class="Button1 Btn_center">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă registru
            </span>
          </div>
        </div>
      </div>
    </div>

    <v-table
      v-if="PERMISIONS.list"
      :reloadCount="reloadTable"
      :tableHead="tableHead"
      :prepareFn="prepareData"
      :apiModule="tableModule"
      :apiModuleName="'REGISTRU_PAGINATED'"
      :tableMinimizeHead="true"
      :tableSpread="true"
      @Btn2="editRegister"
      @Btn3="deleteItem"
      @totalRecords="(x) => (totalRecords = x)"
    />
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/registerPermMixin.js";
import { REGISTRU_PAGINATED, REGISTRU_DELETE } from "@/api.js";
import { mapMutations } from "vuex";
import TableParent from "@/components/TableParent";
import DialogModal from "@/components/DialogModal";

const formatOptions = {
  decimal: ".",
  thousand: ",",
  precision: 2,
  format: "%v",
};
const curr = require("currency-formatter");
const format = (money) => curr.format(money, formatOptions);

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin],
  inject: ["register"],
  components: {
    "v-table": TableParent,
  },
  data() {
    return {
      tableHead: [
        {
          title: "Tipul instituției",
          queryKey: "institutionType",
          bottomHead: {
            quikFiltre: "list",
            options: [
              "Administrație publică locală",
              "Administrație publică centrală",
            ],
          },
        },
        {
          title: "Categoria achiziției",
          queryKey: "achizitionCategory",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Tipul achiziției",
          queryKey: "procedureType.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea minimă (Lei)",
          queryKey: "minValueInLei",
          bottomHead: {
            quikFiltre: "numberFloat",
            range: true,
          },
        },
        {
          title: "Valoarea maximă (Lei)",
          queryKey: "maxValueInLei",
          bottomHead: {
            quikFiltre: "numberFloat",
            range: true,
          },
        },
        {
          title: "Valoarea minimă (Eur)",
          queryKey: "minValueInEU",
          bottomHead: {
            quikFiltre: "numberFloat",
            range: true,
          },
        },
        {
          title: "Valoarea maximă (Eur)",
          queryKey: "maxValueInEU",
          bottomHead: {
            quikFiltre: "numberFloat",
            range: true,
          },
        },
        {
          title: "Curs de schimb",
          queryKey: "exchangeRate",
          bottomHead: {
            quikFiltre: "numberFloat",
          },
        },

        {
          title: "Termeni",
          queryKey: "terms",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Formalități",
          queryKey: "formalities",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Data creării",
          queryKey: "createdAt",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
      ],
      reloadTable: 0,
      tableModule: REGISTRU_PAGINATED,
      totalRecords: 0,
    };
  },
  watch: {
    register: {
      handler() {
        this.reloadPage();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["addWindow"]),
    reload() {
      this.forceReload++;
    },
    addItem() {
      this.addWindow({
        title: "Adaugă registru",
        modal: 24,
      });
    },
    reloadPage() {
      this.reloadTable++;
      this.totalRecords = 0;
    },
    editRegister(item) {
      if (!this.isObject(item)) return;

      this.addWindow({
        title: "Editare registru",
        data: item,
        modal: 24,
      });
    },
    prepareData(row) {
      const preparation = [
        row.institutionType,
        row.achizitionCategory,
        row.procedureType.name,
        format(row.minValueInLei),
        format(row.maxValueInLei),
        format(row.minValueInEU),
        format(row.maxValueInEU),
        row.exchangeRate,
        row.terms,
        row.formalities,
        this.toDateAndTime(row.createdAt),
      ];

      const buttons = [];

      if (this.PERMISIONS.edit) {
        buttons.push(2);
      }

      if (this.PERMISIONS.delete) {
        buttons.push(3);
      }
      preparation.push([buttons]);
      return preparation;
    },

    deleteItem(data) {
      if (!Number.isInteger(data?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteRegistru",
          title: "Șterge registrul",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi registrul selectat?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Ștergerea registrului a eșuat.");

                this.setLoad();
              };

              this.setSafeLoad(12000);
              REGISTRU_DELETE(data.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Registrul a fost ștears.");
                    this.reloadPage();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteRegistru",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
  },
  created() {
    if (this.PERMISIONS.list && this.PERMISIONS.edit) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
  },
};
</script>
